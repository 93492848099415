import { IArithmeticalEvaluation } from "../../interfaces/Rights";

interface StackedBarChartProps {
    data: IArithmeticalEvaluation[];
    showIndicators: boolean;
}

interface IPercentages {
    positivosPercentage: number | null;
    negativosPercentage: number | null;
    neutrosPercentage: number | null;
}

interface IColors {
    positivosColor: string;
    negativosColor: string;
    neutrosColor: string;
}

interface IDataWithDetails extends IArithmeticalEvaluation {
    percentages: IPercentages;
    colors: IColors;
}

// --> FUNCIÓN PARA CALCULAR EL PORCENTAJE <-- //
const calculatePercentages = (data: IArithmeticalEvaluation[] = []): IDataWithDetails[] => {
    if (!Array.isArray(data) || data.length === 0) {
        return [];
    }

    if (data.some(item => item.total_con_valor === 0)) {
        return [];
    }

    return data && data?.map(item => {
        const { total_con_valor, positivos, negativos, neutros } = item;

        let positivosPercentage = null;
        let negativosPercentage = null;
        let neutrosPercentage = null;

        if (positivos !== null && negativos !== null && neutros !== null && total_con_valor !== null) {
            const rawPositivos = (positivos / total_con_valor) * 100;
            const rawNegativos = (negativos / total_con_valor) * 100;
            const rawNeutros = (neutros / total_con_valor) * 100;

            // Ajustar para que la suma sea 100%
            const totalRaw = rawPositivos + rawNegativos + rawNeutros;
            const adjustment = 100 - totalRaw;

            if (rawPositivos >= rawNegativos && rawPositivos >= rawNeutros) {
                positivosPercentage = rawPositivos + adjustment;
                negativosPercentage = rawNegativos;
                neutrosPercentage = rawNeutros;
            } else if (rawNegativos >= rawPositivos && rawNegativos >= rawNeutros) {
                negativosPercentage = rawNegativos + adjustment;
                positivosPercentage = rawPositivos;
                neutrosPercentage = rawNeutros;
            } else {
                neutrosPercentage = rawNeutros + adjustment;
                positivosPercentage = rawPositivos;
                negativosPercentage = rawNegativos;
            }
        }

        return {
            ...item,
            percentages: {
                positivosPercentage,
                negativosPercentage,
                neutrosPercentage,
            },
            colors: {
                positivosColor: "#00FFA3",
                negativosColor: "#FF0909",
                neutrosColor: "#FFD952",
            },
        };
    });
};

// --> BARRA DEL GRÁFICO <-- //
interface BarProps {
    percentage: number | null;
    color: string;
    value: number | null;
    showIndicator: boolean;
}

const Bar: React.FC<BarProps> = ({ percentage, color, value, showIndicator }) => (
    <div style={{ width: `${percentage}%` }}>
        {showIndicator && percentage && percentage > 0 ? <p className="text-llarge text-primary-40 text-center">{value}</p> : null}
        <div style={{ backgroundColor: color, height: '6px' }} className="rounded-lg"></div>
    </div>
);


// --> Gráfico de barras <-- //
const StackedBarChartStates = ({ data, showIndicators }: StackedBarChartProps) => {
    const dataWithDetails = calculatePercentages(data);
    return (
        <>
            {
                dataWithDetails && dataWithDetails.length > 0 ?
                    dataWithDetails.map((element, index) => (
                        <div key={index}>
                            <div className="flex gap-1">
                                <Bar percentage={element.percentages.negativosPercentage} color={element.colors.negativosColor} value={element.negativos} showIndicator={showIndicators} />
                                <Bar percentage={element.percentages.neutrosPercentage} color={element.colors.neutrosColor} value={element.neutros} showIndicator={showIndicators} />
                                <Bar percentage={element.percentages.positivosPercentage} color={element.colors.positivosColor} value={element.positivos} showIndicator={showIndicators} />
                            </div>
                        </div>
                    ))
                    :
                    <div>
                        <p className="text-llarge medium text-[#929091] text-center">Sin datos</p>
                        <div style={{ backgroundColor: '#929091', height: '6px' }} className="rounded-lg w-full"></div>
                    </div>
            }
        </>
    );
};

export default StackedBarChartStates;