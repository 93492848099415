import { AllData } from "../interfaces/Indicator";

function hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
        var r = parseInt(result[1], 16);
        var g = parseInt(result[2], 16);
        var b = parseInt(result[3], 16);
        return r + "," + g + "," + b;
    }
    return undefined;
}

function titleCase(string: string) {
    let stringCapitalize = string[0].toLocaleUpperCase() + string.slice(1).toLowerCase()
    return stringCapitalize
}

function getImageFormat(filename: string): string | null {
    const regex = /(?:\.([^.]+))?$/;
    const match = regex.exec(filename);

    if (match && match[1]) {
        const format = match[1].toLowerCase();
        const validFormats = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'svg'];

        if (validFormats.includes(format)) {
            return format;
        } else {
            return 'Invalid format';
        }
    }

    return null;
}

const getValueID = (string: string | undefined) => {
    if (string !== undefined) {
        const expresionRegular = /id(\d+)/;
        const value_ID = string.match(expresionRegular);

        if (value_ID) {
            return value_ID[1]

        } else {
            return null
        }
    } else {
        return
    }
}

type Predicate = (key: string) => boolean;

const getDomain = (data: any[], searchPredicate: Predicate): [number, number] => {
    const findKey = (item: any) => Object.keys(item).find(searchPredicate);

    const values = data
        .map(item => {
            const key = findKey(item);
            return key ? parseFloat(item[key]) : NaN;
        })
        .filter(value => !isNaN(value));

    if (values.length === 0) {
        return [0, 1];
    }

    const min = Math.min(...values);
    const max = Math.max(...values);
    const padding = (max - min) * 0.1;  // 10% padding on top and bottom
    return [min - padding, max + padding];
};

/**
 * Función de filtro de indicadores
*/
const filterByCriteria = (
    criteria: { [key: string]: string },
    data: AllData[],
    pivot: string // Variable pivot para agrupar los datos
) => {
    if (!Array.isArray(data) || data.length === 0) {
        return []; // Retornar vacío si los datos no son válidos
    }

    const filteredData = data.filter(item => {
        return Object.keys(criteria).every(key => {
            const filterValue = criteria[key]?.toLowerCase();

            if (filterValue === 'todos') {
                return true; // No filtrar este criterio
            }

            // Extraer la parte del encabezado que corresponde al criterio
            const matchingPart = item.encabezado
                ?.toLowerCase() // Convertir el encabezado completo a minúsculas
                .split('-')
                .find(part => part.split('.')[0].trim() === key.toLowerCase().trim());

            if (!matchingPart) {
                return false; // No coincide con la clave del criterio
            }

            // Verificar si el valor exacto está en la parte del encabezado
            const extractedValue = matchingPart.split('.')[1]?.trim().toLowerCase();

            return extractedValue === filterValue;
        });
    });

    const groupedData: { [key: string]: any } = {};

    filteredData.forEach(({ anio, encabezado, valor }) => {
        if (!encabezado || !valor || isNaN(parseFloat(valor))) return;

        const parts = encabezado.toLowerCase().split('-'); // Convertir encabezado a minúsculas y dividir

        // Caso 1: Encabezado con '.'
        if (parts.length === 1 && encabezado.includes('.')) {
            const [variable, value] = encabezado.toLowerCase().split('.').map(str => str.trim());
            if (variable === pivot.toLowerCase()) {
                if (!groupedData[anio]) {
                    groupedData[anio] = { name: anio };
                }
                groupedData[anio][value] = (groupedData[anio][value] || 0) + parseFloat(valor);
            }
        }

        // Caso 2: Encabezados compuestos
        else if (parts.length > 1) {
            const matchingPart = parts.find(part => part.startsWith(pivot.toLowerCase()));
            if (matchingPart) {
                const simplifiedHeader = matchingPart.includes('.')
                    ? matchingPart.split('.')[1].trim().toLowerCase()
                    : matchingPart.trim();

                if (!groupedData[anio]) {
                    groupedData[anio] = { name: anio };
                }

                groupedData[anio][simplifiedHeader] = (groupedData[anio][simplifiedHeader] || 0) + parseFloat(valor);
            }
        }

        // Caso 3: Encabezados simples
        else if (parts.length === 1) {
            const key = parts[0].trim().toLowerCase();
            if (key === pivot.toLowerCase()) {
                if (!groupedData[anio]) {
                    groupedData[anio] = { name: anio };
                }
                groupedData[anio][key] = (groupedData[anio][key] || 0) + parseFloat(valor);
            }
        }
    });

    // Transformación final: Reemplazar valores NaN con null y capitalizar claves excepto "name", la libreria solo toma 'name' no 'Name'
    return Object.values(groupedData).map((item: any) => {
        const capitalizedItem: { [key: string]: any } = {};

        Object.entries(item).forEach(([key, value]) => {
            const capitalizedKey = key === 'name' ? key : key.charAt(0).toUpperCase() + key.slice(1);
            capitalizedItem[capitalizedKey] = typeof value === 'number' && isNaN(value) ? null : value;
        });

        return capitalizedItem;
    });
};

const formatObjectToString = (obj: Record<string, string>): string => {
    return Object.entries(obj)
        .map(([key, value]) => `${key}-${value}`)
        .join('_'); // Usa el separador que prefieras, en este caso '_'
};

export { hexToRgb, titleCase, getImageFormat, getValueID, getDomain, filterByCriteria, formatObjectToString }