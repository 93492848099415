import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import '../styles.css'
import { titleCase } from '../../utils/utils';

interface IBreadcrumbsNamedProps {
    baseName?: string;
}

const Breadcrumbs = ({ baseName = '' }: IBreadcrumbsNamedProps) => {
    const location = useLocation();
    let currentLink = "";

    const decodedPathname = location.pathname;
    const expresionRegular = /id(\d+)/;

    const crumbs = decodedPathname.split('/')
        .filter((crumb) => crumb !== '')
        .map((crumb, index, array) => { // Agrega array como tercer parámetro
            currentLink += `/${crumb}`;

            const isCurrentLink = currentLink === location.pathname;

            // Conditional rendering for "Qué es?" when crumb is "que-es"
            let crumbText = titleCase(decodeURIComponent(crumb.replaceAll('-', ' ').replace(expresionRegular, '')));
            if (crumb === 'que-es') {
                crumbText = '¿Qué es?';
            }

            if (crumb === 'metodologia') {
                crumbText = baseName;
            }

            const isLast = index === array.length - 1; // Verifica si es el último breadcrumb

            return (
                <p key={crumb} className='flex gap-1'>
                    {index > 0 && " / "}
                    {isLast ? (
                        // Renderiza el último breadcrumb como un span
                        <span className=" text-primary-40 truncate max-w-64 sm:max-w-80 lg:max-w-full block">
                            {crumbText}
                        </span>
                    ) : (
                        <Link
                            to={currentLink}
                            className={isCurrentLink ? "text-[#70787D]   lg:max-w-full block" : ""}
                        >
                            {crumbText}
                        </Link>
                    )}
                </p>
            );
        });

    return (
        <div className='flex flex-wrap gap-1 text-tmedium text-[#70787D] '>
            Defensoría / {crumbs}
        </div>
    );
};

export default Breadcrumbs;