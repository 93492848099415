import { RefObject } from "react";
import useDataKey from "./useDataKey";
import { Graphics } from "../interfaces/Indicator";
import { INDICATOR_TYPE_PROCESS } from "../utils/const";

const useDownloadChart = (
  chartRef: RefObject<any>,
  chart: Graphics,
  source: string,
  typeOfIndicator: string,
  filtersSelected: { [key: string]: string },
  breadcrumbs: string,
  name_indicator: string
) => {
  const { dataKey } = useDataKey(chart?.data_formated!);

  // Función para dividir el texto en líneas, si es necesario
  const wrapText = (
    ctx: CanvasRenderingContext2D,
    text: string,
    x: number,
    y: number,
    maxWidth: number,
    lineHeight: number
  ) => {
    const words = text.split(" ");
    let line = "";
    let lineY = y;

    for (let i = 0; i < words.length; i++) {
      const testLine = line + words[i] + " ";
      const testWidth = ctx.measureText(testLine).width;
      if (testWidth > maxWidth && i > 0) {
        ctx.fillText(line, x, lineY);
        line = words[i] + " ";
        lineY += lineHeight; // Se ajusta la altura de la línea
      } else {
        line = testLine;
      }
    }
    ctx.fillText(line, x, lineY);
  };

  //Calcular la altura del canva
  const calculateCanvasHeight = (
    chart: Graphics,
    filtersSelected: { [key: string]: string },
    dataKey: any[],
    typeOfIndicator: string,
    svgHeight: number,
    ctx: CanvasRenderingContext2D,
    margin: number,
    titlesHeight: number,
    legendHeight: number,
    noteAndSourceHeight: number
  ) => {
    let totalHeight = margin; // Altura inicial (margen superior)

    // Calcular altura del breadcrumb
    totalHeight += 30;

    // Calcular altura del título
    totalHeight += 30;

    // Calcular altura de los filtros seleccionados
    if (filtersSelected && Object.keys(filtersSelected).length > 0) {
      totalHeight += 20; // Espacio para el header de filtros
      totalHeight += Object.keys(filtersSelected).length * 20; // Cada línea de filtro
      totalHeight += 10; // Espacio adicional después de los filtros
    }

    // Añadir la altura del gráfico SVG
    totalHeight += svgHeight;

    // Calcular altura de la leyenda
    const maxWidth = ctx.canvas.width - margin * 2 - 40;
    let currentLineWidth = 0;
    let legendLines = 1;

    const legendItems =
      typeOfIndicator === INDICATOR_TYPE_PROCESS
        ? dataKey?.filter((item) =>
            item.key.toLowerCase().includes("variación")
          )
        : dataKey;

    legendItems?.forEach((item) => {
      const textWidth = ctx.measureText(item.key).width;
      const legendItemWidth = 16 + 5 + textWidth + 25;

      if (currentLineWidth + legendItemWidth > maxWidth) {
        currentLineWidth = 0;
        legendLines++; // Salto a la siguiente línea
      }
      currentLineWidth += legendItemWidth;
    });

    totalHeight += legendLines * 25 + legendHeight;

    // Calcular altura de la nota y la fuente
    const lineHeight = 18;

    if (chart?.nota_grafico?.length! > 0) {
      const noteLines = Math.ceil(chart?.nota_grafico?.length! / 60);
      totalHeight += 20 + noteLines * lineHeight;
    }

    if (source?.length > 0) {
      totalHeight += 20 + lineHeight;
    }

    // Añadir margen inferior
    totalHeight += margin;

    return totalHeight;
  };

  const downloadChart = (format: "png" | "jpg" = "png") => {
    if (chartRef.current) {
      const svgElement = chartRef.current.querySelector("svg");
      if (svgElement) {
        const svgData = new XMLSerializer().serializeToString(svgElement);
        const canvas = document.createElement("canvas");
        const svgSize = svgElement.getBoundingClientRect();

        const ctx = canvas.getContext("2d");
        if (!ctx) return;
        // Ajusta el tamaño del canvas para incluir título, filtros, gráfico y leyenda
        const titlesHeight = 100;
        const legendHeight = 30;
        const noteAndSource = 200;
        const canvasPadding = 20;
        const margin = 20;

        canvas.width = svgSize.width + canvasPadding + margin * 2;
        canvas.height = calculateCanvasHeight(
          chart,
          filtersSelected,
          dataKey!,
          typeOfIndicator,
          svgSize.height,
          ctx!,
          margin,
          titlesHeight,
          legendHeight,
          noteAndSource
        );

        // const ctx = canvas.getContext("2d");
        const img = new Image();
        const svgBlob = new Blob([svgData], {
          type: "image/svg+xml;charset=utf-8",
        });
        const url = URL.createObjectURL(svgBlob);

        img.onload = () => {
          if (ctx) {
            if (format === "jpg") {
              ctx.fillStyle = "#FFFFFF";
              ctx.fillRect(0, 0, canvas.width, canvas.height);
            }

            // Inicializa el acumulador de altura
            let contentHeight = margin + 20;

            // Dibuja el breadcrumb en la parte superior del canvas
            ctx.font = "14px Roboto";
            ctx.fillStyle = "#000";
            ctx.textAlign = "left";

            wrapText(
              ctx,
              `${breadcrumbs.replaceAll(">", " / ")} / ${name_indicator}`,
              margin,
              contentHeight,
              canvas.width - margin * 2,
              0
            );
            contentHeight += 20 * Math.ceil(breadcrumbs.length / 60); // Ajusta según las líneas del breadcrumb

            // Dibuja el título en la parte superior del canvas
            ctx.font = "bold 20px Roboto";
            ctx.fillStyle = "#000";
            ctx.textAlign = "left";
            ctx.fillText(chart.titulo, margin, contentHeight);
            contentHeight += 30; // Añade espacio debajo del título

            // Dibuja los filtros seleccionados debajo del título
            if (filtersSelected && Object.keys(filtersSelected).length > 0) {
              const filtersTextLines = Object.entries(filtersSelected).map(
                ([key, value]) => `${key}: ${value}`
              );

              ctx.font = "14px Roboto";
              ctx.fillStyle = "#000";

              ctx.fillText("Filtros seleccionados:", margin, contentHeight);
              contentHeight += 20; // Espacio para el header de filtros

              filtersTextLines.forEach((line) => {
                ctx.fillStyle = "#666";
                ctx.fillText(line, margin, contentHeight);
                contentHeight += 20; // Espacio para cada línea de filtro
              });

              contentHeight += 10; // Espacio adicional después de los filtros
            }

            // Dibuja el gráfico SVG debajo de los filtros seleccionados
            ctx.drawImage(img, margin, contentHeight); // Ajusta la imagen con el nuevo contentHeight
            contentHeight += svgSize.height; // Añade espacio después del gráfico

            // Filtrar los items de la leyenda según los filtros seleccionados
            const legendItems =
              Object.entries(filtersSelected).length > 0
                ? dataKey?.filter((item) => {
                    return Object.entries(filtersSelected).some(
                      ([key, value]) => {
                        const lowerCaseKey = key.toLowerCase();
                        const lowerCaseValue = value.toLowerCase();
                        return (
                          item.key.toLowerCase().includes(lowerCaseKey) ||
                          item.key.toLowerCase().includes(lowerCaseValue)
                        );
                      }
                    );
                  })
                : dataKey;

            ctx.font = "14px Roboto";
            let currentLineWidth = 0;
            const maxWidth = canvas.width - margin * 2 - 40;

            legendItems?.forEach((item) => {
              const textWidth = ctx.measureText(item.key).width;
              const legendItemWidth = 16 + 5 + textWidth + 25;

              if (currentLineWidth + legendItemWidth > maxWidth) {
                currentLineWidth = 0;
                contentHeight += 25; // Salto a la siguiente línea
              }

              ctx.fillStyle = item.color;
              ctx.fillRect(margin + currentLineWidth, contentHeight, 16, 16);
              ctx.fillStyle = item.color;
              ctx.fillText(
                item.key,
                margin + currentLineWidth + 25,
                contentHeight + 12
              );

              currentLineWidth += legendItemWidth;
            });

            contentHeight += legendHeight + 20; // Espacio después de la leyenda

            // Dibuja la nota y el source debajo de la leyenda
            const lineHeight = 18;
            ctx.font = "14px Roboto";
            ctx.fillStyle = "#000";

            if (chart && chart?.nota_grafico?.length! > 0) {
              wrapText(
                ctx,
                `Nota: ${chart.nota_grafico}`,
                margin,
                contentHeight,
                maxWidth,
                lineHeight
              );
              contentHeight +=
                20 + lineHeight * Math.ceil(chart?.nota_grafico?.length! / 60); // Ajustar la altura según el texto
            }

            if (source?.length > 0) {
              wrapText(
                ctx,
                `Fuente: ${source}`,
                margin,
                contentHeight,
                maxWidth,
                lineHeight
              );
              contentHeight += 20 + lineHeight; // Ajustar la altura según el texto
            }

            URL.revokeObjectURL(url);

            // Crear un enlace para descargar la imagen
            const imgURL = canvas.toDataURL(`image/${format}`);
            const downloadLink = document.createElement("a");
            downloadLink.href = imgURL;
            downloadLink.download = `${chart.titulo}.${format}`;
            downloadLink.click();
          }
        };

        img.src = url;
      }
    }
  };

  return { downloadChart };
};

export default useDownloadChart;
