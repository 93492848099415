import * as XLSX from "xlsx";
import { BaseMap, ChartData } from "../../../interfaces/Indicator";

const exportToExcel = (
  data: ChartData[] | BaseMap[],
  source: string,
  fileName: string,
  filtersSelected: { [key: string]: string },
  nota_grafico: string,
  breadcrumbs: string,
  name_indicator: string
) => {
  if (!data || data.length === 0) {
    console.warn("No hay datos para exportar.");
    return;
  }

  const worksheetData: any[] = [];

  worksheetData.push([
    `${breadcrumbs.replaceAll(">", " / ")} / ${name_indicator}`,
  ]);
  worksheetData.push([fileName]);

  // Filtros seleccionados
  if (filtersSelected && Object.keys(filtersSelected).length > 0) {
    worksheetData.push(["Filtros seleccionados"]);
    Object.entries(filtersSelected).forEach(([key, value]) => {
      worksheetData.push([`${key}: ${value}`]);
    });
    worksheetData.push([]); // Espacio
  }

  // Obtener los encabezados dinámicamente según el primer elemento
  const isChartData = (item: ChartData | BaseMap): item is ChartData =>
    "name" in item; // Aseguramos que sea un ChartData si tiene la propiedad "name"

  const headers = isChartData(data[0])
    ? Object.keys(data[0]) // ChartData
    : Object.keys(data[0]); // BaseMap

  const updatedHeaders = headers.map((header) =>
    header === "name" ? "Año" : header
  );
  worksheetData.push(updatedHeaders); // Agregar encabezados

  // Mapear los datos dinámicamente
  worksheetData.push(
    ...data.map((item) =>
      updatedHeaders.map((header) => {
        const originalHeader = header === "Año" ? "name" : header;

        const value = item[originalHeader as keyof typeof item];

        // Si es un array, convertirlo en JSON o en un formato legible
        if (Array.isArray(value)) {
          return JSON.stringify(value); // Convierte arrays en cadenas JSON
        }

        // Devolver el valor o cadena vacía si es null/undefined
        return value ?? "";
      })
    )
  );

  // Espacio adicional
  worksheetData.push([]);

  // Nota del gráfico
  if (nota_grafico?.trim()) {
    worksheetData.push([`Nota: ${nota_grafico}`]);
  }

  // Fuente
  if (source?.trim()) {
    worksheetData.push([`Fuente: ${source}`]);
  }

  // Crear hoja de cálculo y archivo Excel
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Datos");

  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

export default exportToExcel;
