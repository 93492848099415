import { MdChevronLeft } from "react-icons/md";
import { MdChevronRight } from "react-icons/md";

type PaginationProps<T> = {
  data: T[];
  totalPages: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
};

const Pagination = <T,>({
  data,
  totalPages,
  currentPage,
  setCurrentPage,
}: PaginationProps<T>) => {
  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
    scrollToTop();
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSelectPage = (page: number) => {
    if (typeof page === "number") {
      setCurrentPage(page);
      scrollToTop();
    } else {
      return null;
    }
  };

  const getPaginationRange = (): (number | string)[] => {
    const range: (number | string)[] = [];
    const showPages = 1; // Números visibles al inicio y al final
    const surroundingPages = 1; // Números visibles alrededor de la actual

    if (totalPages <= showPages + surroundingPages * 2 + 2) {
      // Mostrar todas las páginas si son pocas
      for (let i = 1; i <= totalPages; i++) {
        range.push(i);
      }
    } else {
      // Agregar los primeros números
      for (let i = 1; i <= showPages; i++) {
        range.push(i);
      }

      // Agregar "..." si hay un salto
      if (currentPage > showPages + surroundingPages) {
        range.push("...");
      }

      // Agregar páginas alrededor de la actual
      for (
        let i = Math.max(currentPage - surroundingPages, showPages + 1);
        i <= Math.min(currentPage + surroundingPages, totalPages - showPages);
        i++
      ) {
        range.push(i);
      }

      // Agregar "..." antes de los últimos números
      if (currentPage + surroundingPages < totalPages - showPages) {
        range.push("...");
      }

      // Agregar los últimos números
      for (let i = totalPages - showPages + 1; i <= totalPages; i++) {
        range.push(i);
      }
    }

    return range;
  };

  const paginationRange = getPaginationRange();

  return (
    <>
      {data?.length > 0 ? (
        <div className="mb-6 flex items-center justify-center mt-4">
          <div className="flex items-center justify-center gap-3">
            <button
              className={`page-item previous ${
                currentPage <= 1
                  ? "disabled:opacity-25 cursor-not-allowed "
                  : ""
              }`}
              onClick={() => handlePreviousPage()}
              disabled={currentPage === 1}
            >
              <MdChevronLeft size={28} />
            </button>
            {/* Rango de paginación */}
            {paginationRange.map((page, index) => (
              <button
                key={index}
                className={`${
                  page === currentPage
                    ? "text-tmedium  text-primary-0"
                    : "text-blarge  text-system-light-50"
                } ${page === "..." ? "cursor-default" : ""}`}
                onClick={() => handleSelectPage(Number(page))}
                disabled={page === "..."}
              >
                {page}
              </button>
            ))}
            <button
              className={`page-item next ${
                currentPage >= totalPages
                  ? "disabled:opacity-25 cursor-not-allowed"
                  : ""
              }`}
              onClick={() => handleNextPage()}
              disabled={currentPage === totalPages}
            >
              <MdChevronRight size={28} />
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Pagination;
