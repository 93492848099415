import { useState, useEffect, useRef } from 'react'
import SuperFilter from './SuperFilter'
import ResponsiveContainer from '../../../../layouts/ResponsiveContainer'
import '../../../../index.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { getRights } from '../../../../redux/slices/rights/rightsThunk'
import { getExtendedRight, getSearchOfIndicators } from '../../../../redux/slices/indicatorDisplay/SearchAndFilter/filterAndSearchThunk'
import { cleanOptions, cleanSearch, setDimensionOptions, setIndicatorsOptions, setSelectedPeriod, setSubDimensionOptions } from '../../../../redux/slices/indicatorDisplay/SearchAndFilter/filterAndSearchSlice'
import PredictiveSearchBar from '../../../../components/SearchBar/PredictiveSearchBar'
import { getIndicators } from '../../../../redux/slices/indicatorDisplay/indicator/indicatorThunk'
import Dropdown from '../../../../components/Inputs/Selects/CustomSelect';

const SearchAndFilter = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  const currentRight = params.get('right') || '';
  const prevRight = useRef<string | null>(null);

  //Redux states
  const rightOptions = useAppSelector((state) => state.rights.rights_options);
  const {
    indicatorTypesOptions,
    selectedRight,
    dimensionOptions,
    subDimensionOptions,
    indicatorsOptions,
    searchOfIndicators,
    loadingSearch,
    errorSearch,
  } = useAppSelector((state) => state.indicator_display_filter);

  const { periods, lastPeriod } = useAppSelector((state) => state.periods)

  // ---> START STATES <--- //
  //Filter
  const [right, setSelectedRight] = useState<string>('');
  const [selectedIndicatorType, setSelectedIndicatorType] = useState<string>('');
  const [selectedDimention, setSelectedDimention] = useState<string>('');
  const [selectedSubDimention, setSelectedSubDimention] = useState<string>('');
  const [selectedIndicator, setSelectedIndicator] = useState<string>('');

  //Search
  const [searchValue, setSearchValue] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [cleanValue, setCleanValue] = useState<boolean>(false);

  //Period
  const [period, setPeriod] = useState<string>('');

  // Disabled
  const [disabledTypeIndicator, setDisabledTypeIndicator] = useState<boolean>(true);
  const [disabledDimention, setDisabledDimention] = useState<boolean>(true);
  const [disabledSubDimention, setDisabledSubDimention] = useState<boolean>(true);
  const [disabledIndicator, setDisabledIndicator] = useState<boolean>(true);
  const [disabledButtonApplyFilter, setDisabledButtonApplyFilter] = useState<boolean>(true);
  const [disabledButtonCleanFilter, setdisabledButtonCleanFilter] = useState<boolean>(true);
  // ---> END STATES <--- //

  useEffect(() => {
    if (!rightOptions?.length) {
      dispatch(getRights(lastPeriod || ''));
    }

    if (currentRight !== prevRight.current && rightOptions !== null) {
      dispatch(getExtendedRight({ right: currentRight }));
      prevRight.current = currentRight;
    }
  }, [params.get('right'), rightOptions, selectedRight, dispatch]);

  useEffect(() => {
    if (selectedRight && selectedRight?.tipos_indicadores?.length) {
      setSelectedRight(currentRight);
      setDisabledTypeIndicator(false);
    }
  }, [selectedRight]);

  useEffect(() => {
    if ((searchOfIndicators && searchOfIndicators?.length) || loadingSearch || (errorSearch && errorSearch.length > 0)) {
      setOpen(true)
    } else setOpen(false);
  }, [searchOfIndicators, loadingSearch, errorSearch]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const search = params.get('search') || '';
    const type = (params.get('type') || '').replaceAll('+', ' ');
    const dimension = (params.get('dimension') || '').replaceAll('+', ' ');
    const subdimension = (params.get('subdimension') || '').replaceAll('+', ' ');
    const indicator = (params.get('indicator') || '').replaceAll('+', ' ');

    // Procesar solo si el derecho (selectedRight) está cargado
    if (!selectedRight) {
      return;
    }

    if (type) {
      setSelectedIndicatorType(type);
      dispatch(setDimensionOptions({ typeOfIndicator: type }));
      setdisabledButtonCleanFilter(false);
      setDisabledDimention(false);
    }

    if (dimension) {
      setSelectedDimention(dimension);
      setDisabledDimention(false);
      setDisabledSubDimention(false);
      dispatch(setSubDimensionOptions({ dimension: dimension }));
      setdisabledButtonCleanFilter(false);
    }

    if (subdimension) {
      setSelectedSubDimention(subdimension);
      setDisabledSubDimention(false);
      setDisabledIndicator(false);
      dispatch(setIndicatorsOptions({ subdimension: subdimension }));
      setdisabledButtonCleanFilter(false);
    }

    if (indicator) {
      setSelectedIndicator(indicator);
      setdisabledButtonCleanFilter(false);
      setDisabledIndicator(false);
    }

    if (search) {
      setSearchValue(search);
      setCleanValue(true);
    }
  }, [location.search, selectedRight]);

  //useEffect handle periods
  useEffect(() => {
    const period = params.get('period') || '';
    if (period) {
      setPeriod(period)
      dispatch(setSelectedPeriod({ period }))
      //periodo de la landing
      dispatch(setSelectedPeriod({ period }))
    }
  }, [location.search])

  useEffect(() => {
    return () => {
      dispatch(cleanSearch()); // Acción para limpiar el estado de errorSearch
    };
  }, [dispatch]);


  //---> START SEARCH <--- //  
  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    setError(false);
    // Expresión regular que permite letras y espacios solo entre palabras
    if (/^[A-Za-záéíóúüÁÉÍÓÚÜ]+(\s[A-Za-záéíóúüÁÉÍÓÚÜ]+)*$/.test(e.target.value)) {
      setError(false);
      if (e.target.value.length >= 3) {
        dispatch(getSearchOfIndicators({ indicator: e.target.value, right }));
      } else {
        dispatch(cleanSearch());
      }
    } else {
      setError(true);
      if (open) setOpen(false);
    }
  };

  const selectSearch = ({ indicator, indicatorID }: { indicator: string, indicatorID: number }) => {
    if (indicator && indicatorID) {
      setSearchValue(indicator);
      params.set('search', indicator);
      params.set('indicatorID', indicatorID.toString());
      params.set('page', "1");
      navigate({ search: params.toString() });
      dispatch(getIndicators({ right: right, search: indicator, page: 1, limit: 5 }));
      setOpen(false);
      setCleanValue(true);
      dispatch(cleanSearch());
    }
  }

  const onCleanValueSearch = () => {
    setSearchValue('');
    dispatch(getIndicators({ right: right, page: 1, limit: 5 }));
    params.delete('search');
    params.delete('indicatorID');
    navigate({ search: params.toString() });
    setCleanValue(false);
    dispatch(cleanSearch());
  }
  //---> END SEARCH <--- //  

  //---> CLEAN STATES <--- //
  const cleanOptionsAndQuerys = (value: 'typeIndicator' | 'dimension' | 'subdimension' | 'indicators') => {
    switch (value) {
      case 'typeIndicator':
        dispatch(cleanOptions('dimension'));
        setSelectedIndicatorType('');
        setSelectedDimention('');
        setSelectedSubDimention('');
        setSelectedIndicator('');
        setDisabledTypeIndicator(false);
        setDisabledDimention(true);
        setDisabledSubDimention(true);
        setDisabledIndicator(true);
        setDisabledButtonApplyFilter(true);
        setdisabledButtonCleanFilter(true);
        params.delete('type');
        params.delete('dimension');
        params.delete('subdimension');
        params.delete('indicator');
        params.delete('indicatorID');
        navigate({ search: params.toString() });
        if (selectedIndicatorType) {
          dispatch(getIndicators({ right: right, page: 1, limit: 5 }));
        }
        break
      case 'dimension':
        dispatch(cleanOptions('dimension'));
        setSelectedDimention('');
        setSelectedSubDimention('');
        setSelectedIndicator('');
        setDisabledSubDimention(true);
        setDisabledIndicator(true);
        setDisabledButtonApplyFilter(true);
        setdisabledButtonCleanFilter(true);
        params.delete('dimension');
        params.delete('subdimension');
        params.delete('indicator');
        break;
      case 'subdimension':
        dispatch(cleanOptions('subdimension'));
        setSelectedSubDimention('');
        setSelectedIndicator('');
        setDisabledIndicator(true);
        setDisabledButtonApplyFilter(true);
        setdisabledButtonCleanFilter(true);
        params.delete('subdimension');
        params.delete('indicator');
        break;
      case 'indicators':
        setSelectedIndicator('');
        setDisabledButtonApplyFilter(true);
        setdisabledButtonCleanFilter(true);
        params.delete('indicator');
        break;
    }
  };

  // ---> START SELECTS OF DROPDOWN <--- //
  const onSelectDropdownRight = (value: string) => {
    if (!value || value === currentRight) {
      return; // Evitar ejecución si el valor es inválido o no cambió
    }

    setSelectedRight(value);

    // Solo limpiar si el valor anterior no es vacío
    if (currentRight) {
      cleanOptionsAndQuerys('typeIndicator');
    }

    // Agregar nueva selección a la URL
    params.set('right', value);
    navigate({ search: params.toString() });
    setDisabledButtonApplyFilter(false);
  };

  const onSelectDropdownTypeIndicator = (value: string) => {
    if (value.length && selectedRight) {
      selectedIndicatorType === '' ? setDisabledDimention(false) : cleanOptionsAndQuerys('dimension');
      setSelectedIndicatorType(value);
      dispatch(setDimensionOptions({ typeOfIndicator: value }));
      params.set('type', value);
      navigate({ search: params.toString() });
      setdisabledButtonCleanFilter(false);
      setDisabledButtonApplyFilter(false);
    }
  };

  const onSelectDropdownDimention = (value: string) => {
    if (value.length && selectedIndicatorType) {
      selectedDimention === '' ? setDisabledSubDimention(false) : cleanOptionsAndQuerys('subdimension');
      setSelectedDimention(value);
      dispatch(setSubDimensionOptions({ dimension: value }));
      params.set('dimension', value);
      navigate({ search: params.toString() });
      setdisabledButtonCleanFilter(false);
      setDisabledButtonApplyFilter(false);
    }
  };

  const onSelectDropdownSubDimention = (value: string) => {
    if (value.length && selectedDimention) {
      selectedIndicator === '' ? setDisabledIndicator(false) : cleanOptionsAndQuerys('indicators');
      setSelectedSubDimention(value);
      dispatch(setIndicatorsOptions({ subdimension: value }));
      params.set('subdimension', value);
      navigate({ search: params.toString() })
      setDisabledButtonApplyFilter(false);
      setdisabledButtonCleanFilter(false);
    }
  };

  const onSelectDropdownIndicator = (value: string) => {
    if (value.length && selectedSubDimention) {
      setSelectedIndicator(value);
      const selectedIndicatorObj = indicatorsOptions?.find((indicator) => indicator.value === value);
      const indicatorId = selectedIndicatorObj?.id || '';

      params.set('indicator', value);
      params.set('indicatorID', indicatorId.toString());
      navigate({ search: params.toString() });

      setDisabledButtonApplyFilter(false);
      setdisabledButtonCleanFilter(false);
    }
  };
  // ---> END SELECTS OF DROPDOWN <--- //

  // ---> START FUNCTION OF FILTER <--- //
  const applyFilter = () => {
    if (selectedIndicator && indicatorsOptions?.length) {
      const selectedIndicatorObj = indicatorsOptions.find((indicator) => indicator.value === selectedIndicator);
      const indicatorId = selectedIndicatorObj?.id || '';

      params.delete('page');
      params.set('page', "1");
      params.set('indicatorID', indicatorId.toString());
      navigate({ search: params.toString() });

      setDisabledButtonApplyFilter(true);
      setdisabledButtonCleanFilter(false);

      dispatch(getIndicators({
        right,
        typeIndicator: selectedIndicatorType,
        dimension: selectedDimention,
        subdimensionId: selectedSubDimention,
        page: 1,
        limit: 5,
        indicadorId: indicatorId.toString()
      }));
    } else {
      // En caso de no tener un indicador seleccionado, enviar solo los demás filtros
      params.delete('page');
      params.set('page', "1");
      navigate({ search: params.toString() });

      setDisabledButtonApplyFilter(true);
      setdisabledButtonCleanFilter(false);

      dispatch(getIndicators({
        right,
        typeIndicator: selectedIndicatorType,
        dimension: selectedDimention,
        subdimensionId: selectedSubDimention,
        page: 1,
        limit: 5,
      }));
    }
  };
  // ---> END FUNCTION OF FILTER <--- //

  // ---> START FUNCTION OF PERIOD <--- //
  const handlePeriod = (period: string) => {
    if (!period) return
    const year = period.split(" ").pop() || '';
    dispatch(setSelectedPeriod({ period: year }))
    setPeriod(year)
    params.set('period', year);
    navigate({ search: params.toString() })
  }

  return (
    <>
      <ResponsiveContainer className=' relative z-[345] evaluation-and-filter-card top-[11rem] mb-16 flex items-end flex-col gap-4'>
        <Dropdown
          options={periods ?? []}
          onSelect={handlePeriod}
          selectedItem={`Período ${period}`}
          defaultSelectedItem={`Período ${period}`}
          label='Período'
          bgSelect='bg-primary-100'
          className='min-w-[150px] max-w-[150px]'
        />
        <div className='bg-primary-100 rounded-lg shadow-lg p-4 flex flex-col gap-6  w-full'>
          <PredictiveSearchBar
            placeholder='Buscar indicador'
            isLoading={loadingSearch}
            searchResults={searchOfIndicators ? searchOfIndicators : []}
            searchValue={searchValue}
            isOpen={open}
            onChange={onChangeSearch}
            searchErrorMessage={errorSearch ? errorSearch : ''}
            generalErrorMessage='Ingrese solo letras'
            hasError={error}
            setHasError={setError}
            selectSearch={selectSearch}
            onCleanValue={onCleanValueSearch}
            cleanValue={cleanValue}
          />
          <div className='border w-full border-system-light-30'></div>
          <SuperFilter
            rightOptions={rightOptions ? rightOptions : []}
            indicatorTypesOptions={indicatorTypesOptions}
            dimensionOptions={dimensionOptions ? dimensionOptions : []}
            subDimensionsOptions={subDimensionOptions ? subDimensionOptions : []}
            indicatorsOptions={indicatorsOptions ? indicatorsOptions : []}
            rightSelected={right}
            indicatorTypeSelected={selectedIndicatorType}
            dimentionSelected={selectedDimention}
            subDimentionSelected={selectedSubDimention}
            indicatorSelected={selectedIndicator}
            onSelectDropdownRight={onSelectDropdownRight}
            onSelectDropdownTypeIndicator={onSelectDropdownTypeIndicator}
            onSelectDropdownDimention={onSelectDropdownDimention}
            onSelectDropdownSubDimention={onSelectDropdownSubDimention}
            onSelectDropdownIndicator={onSelectDropdownIndicator}
            disabledTypeIndicator={disabledTypeIndicator}
            disabledDimention={disabledDimention}
            disabledSubDimention={disabledSubDimention}
            disabledIndicator={disabledIndicator}
            enableCleanButton={disabledButtonCleanFilter}
            enableSendButton={disabledButtonApplyFilter}
            sendData={applyFilter}
            cleanOptionsAndQuerys={() => cleanOptionsAndQuerys('typeIndicator')}
          />
        </div>
      </ResponsiveContainer>
    </>
  )
}

export default SearchAndFilter